import { Customer } from "../model/Customer";
import { apiRoutes } from "../routes";
import { useFetch } from "../utils/reactQuery";

export const useGetCustomers = (repId?: number) => {

    const context = useFetch<Customer[]>(
      `${apiRoutes.getCustomers}/${repId}`,{},
      { retry: false,
        enabled: !!repId
      }
    );

    return { ...context};
};