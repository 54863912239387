import React, { ChangeEvent } from 'react'
import { usePaymentApplicationContext } from '../context/PaymentApplicationContext';
import { emptyItem, emptyTypeDItem, emptyTypeIItem } from '../fixtures/achItems';
import { useGetBusinessUnits } from '../hooks/useGetBusinessUnits';
import { AchItem, TypeDItem, TypeIItem } from '../model/AchItem';
import { business } from '../model/BusinessUnit';
import { Customer } from '../model/Customer';
import { AchType, AchEntry } from '../model/AchEntry';
import { currencyFormatter } from '../utils/helpers';
import { HJButton } from './Button/HJButton';
import { DropdownChangeEvent } from './Dropdown/DropdownChangeEvent';
import { TypeDTableLine } from './TypeDTableLine';
import { TypeITableLine } from './TypeITableLine';

interface AchFormProps {
    submitForm: Function,
    customerOptions?: Customer[],
    achType: AchType,
}

export type UpdateAction = {type: 'creditCard', data: {row: number, value: number}} |
                        {type: 'cashCheck', data: {row: number, value: number}} |
                        {type: 'hjAchAmtRec', data: {changeEvent: ChangeEvent<HTMLInputElement>}} |
                        {type: 'customerName', data: {row: number, changeEvent: DropdownChangeEvent}} |
                        {type: 'customerNumber', data: {row: number, changeEvent: DropdownChangeEvent}} |
                        {type: 'businessUnit', data: {row: number, changeEvent: DropdownChangeEvent}} |
                        {type: 'state', data: {row: number, changeEvent: ChangeEvent<HTMLInputElement> | DropdownChangeEvent}} |
                        {type: 'clear', data: {row: number}};

type Dispatch = (action: UpdateAction) => void;

export const AchForm: React.FC<AchFormProps> = ({submitForm, customerOptions, achType}) => {
    const {formEntry: state, dispatch: setState, repOffice: {officeName} = {}} = usePaymentApplicationContext();
    const {data: businessOptions} = useGetBusinessUnits()

    const updateReducer: Dispatch = (action: UpdateAction) => {
        let newItems = [...state.achItems];
        let newState: AchEntry;
        switch(action.type) {
            case 'creditCard': {
                let {row, value: newValue} = action.data
                if (isNaN(newValue)) { newValue = 0 }
                newItems[row] = {...state.achItems[row], hjCreditCardAmt: newValue};
                newState = {...state, achItems: newItems}
                break;
            }
            case 'cashCheck': {
                let {row, value: newValue} = action.data
                if (isNaN(newValue)) { newValue = 0 }
                newItems[row] = {...state.achItems[row], hjCashCheckAmt: newValue};
                newState = {...state, achItems: newItems}
                break;
            }
            case 'customerName': {
                let {row: i, changeEvent: e} = action.data;
                const options = customerNumbers(e.currentTarget.value);
                newItems[i] = {...state.achItems[i],
                    custName: e.currentTarget.value, 
                    custId: options.length === 1 ? options[0] : ''
                };
                newState = {...state, achItems: newItems};
                break;
            }
            case 'customerNumber': {
                let {row: i, changeEvent: e} = action.data;
                newItems[i] = {...newItems[i], custId: e.currentTarget.value}
                newState = {...state, achItems: newItems};
                break;
            }
            case 'businessUnit': {
                let {row: i, changeEvent: e} = action.data;
                if(!!businessOptions){
                    newItems[i] = {...state.achItems[i], 
                        [e.currentTarget.name]: businessOptions[e.currentTarget.index].businessUnit
                    };
                }
                newState = {...state, achItems: newItems}
                break;
            }
            case 'state': {
                let {row: i, changeEvent: e} = action.data;
                newItems[i] = {...state.achItems[i], [e.currentTarget.name]: e.currentTarget.value};
                newState = {...state, achItems: newItems}
                break;
            }
            case 'clear': {
                let {row: i} = action.data;
                newItems[i] = {...emptyTypeIItem};
                newState = {...state, achItems: newItems}
                break;
            }
            case 'hjAchAmtRec': {
                let {changeEvent: {currentTarget: {value}}} = action.data
                newState = {...state, hjAchAmtRec: parseFloat(value) || 0}
            }
        }
        setState({type: 'setFormEntry', data:{...newState}});
    };

    const getHeaderCategories = () => {
        return [
            '#', 
            'Clear Row', 
            "Customer Name",
            "Customer Number",
        ]
        .concat(achType === 'I' ? [ "Business Unit","Invoice Nbr" ] : ["P&O Nbr"])
        .concat([
            "Credit Cards",
            "Cash/Check",
            "Total",
            "Notes"
        ]);
    };

    const ccTotal = () => state.achItems
            .map((item) => item.hjCreditCardAmt)
            .filter(item => item)
            .reduce((a,b) => a+b, 0);

    const cashCheckTotal = () => state.achItems
            .map((item) => item.hjCashCheckAmt)
            .filter(item => item)
            .reduce((a,b) => a+b, 0);

    const formTotal = () => ccTotal() + cashCheckTotal();
    
    const businessStrings = () => businessOptions?.map(convertToBusinessString) || []

    const convertToBusinessString = (business?: business) => business ? `${business?.businessUnit} - ${business?.descr80}` : ''

    const enteredItems = () => state.achItems.filter(item => JSON.stringify(item) !== JSON.stringify(emptyItem(item)))

    const customerStrings = () => {
        if (customerOptions) {
            let array = [...customerOptions.map((x) => x.customerName)];
            return array;
        }
        return []
    }

    const customerNumbers = (customerName: string) => {
        if (customerOptions) {        
            const foundCustomer = customerOptions?.find(item => item.customerName === customerName)
            if (foundCustomer) return foundCustomer.customerNumbers.split(',');
        }
        return []
    }

    // This runs validation each non-empty line of the table. 
    // Return true if there is a error in the line.
    const lineError = (line: AchItem) => {
        let lineI = line as TypeIItem;
        let lineD = line as TypeDItem;
        const invoiceValidation = /[Jj]?[0-9]+/;
        switch(achType){
            case 'D':
                if (JSON.stringify(lineD) !== JSON.stringify(emptyTypeDItem)) {
                    return !(lineD.hjDepositPo && line.custId && line.custName);
                }
                break;
            case 'I':
                if (JSON.stringify(lineI) !== JSON.stringify(emptyTypeIItem)) {
                    return !(lineI.businessUnit && line.custId && line.custName && invoiceValidation.test(lineI.item));
                }
                break;
        }
        return false;
    }

    const SubmitButton = () => {
        let error = false;
        let errorText = '';
        if(achType !== 'N'){
            state.achItems.forEach((item) =>  { 
                if(lineError(item)) {
                    error = true; 
                    errorText = 'Error on an input line' 
                }
            });
            if (enteredItems().length === 0) { 
                error = true;
                errorText = 'Please enter an item';
            }
        }
        if (achType === 'N' && (state.hjAchAmtRec === undefined || state.hjAchAmtRec <= 0)) { 
            error = true; 
            errorText = 'Please enter an amount greater than 0'
        }
        return (
            <div>
                <HJButton isDisabled={error} clickHandler={()=>submitForm()}>Submit</HJButton>
                {error && <p className='text-red-600 font-bold'>{errorText}</p>}
            </div>
        );
    }

    if (state) {
        if(achType === 'N') {
            return (
                <div className='flex justify-evenly align-middle'>
                    { /*
                    <div className='inline'> 
                        <HJButton>Save Draft</HJButton> 
                        <HJButton>Delete Draft</HJButton>
                    </div>
                    */ }
                    <div className='flex mx-2'>
                        <label htmlFor='total' className='my-auto px-2'>Total:</label>
                        <input type='number' className='w-32 bg-gray-200 border border-gray-200 py-3 px-4 focus:outline-none focus:bg-white focus:border-gray-500' 
                            name='hjAchAmtRec' value={state.hjAchAmtRec} onChange={(e) => updateReducer({type: 'hjAchAmtRec', data: {changeEvent: e}})}/>
                    </div>
                    <div className='my-auto'>
                        <SubmitButton />
                    </div>
                </div>
            );
        }
        return (
            <table className='bg-gray-100'>
                <thead>
                    <tr className='border border-gray-300 bg-gray-300 sticky top-[3.98rem]'>
                        {getHeaderCategories().map(item => <th key={item} className='px-1 mx-2'>{item}</th>)}
                    </tr>
                </thead>
                <tbody className=''>
                    {achType === 'I' && (state.achItems as TypeIItem[]).map((e,i) => (
                        <TypeITableLine 
                            key={i}
                            rowIndex={i} 
                            className={lineError(state.achItems[i]) ? 'border-2 border-red-400' : ''} 
                            achItem={e} 
                            customerNames={customerStrings()} 
                            customerNumbers={customerNumbers(e.custName)} 
                            businessUnits={businessStrings()} 
                            selectedBusiness={convertToBusinessString(businessOptions?.find(item => item.businessUnit === (e).businessUnit))} 
                            updateReducer={updateReducer}
                        />
                    ))}
                    {achType === 'D' && (state.achItems as TypeDItem[]).map((e,i) => (
                        <TypeDTableLine 
                            key={i}
                            rowIndex={i} 
                            className={lineError(state.achItems[i]) ? 'border-2 border-red-400' : ''} 
                            achItem={e} 
                            customerNames={customerStrings()} 
                            customerNumbers={customerNumbers(e.custName)} 
                            updateReducer={updateReducer}
                        />
                    ))}
                </tbody>
                <tfoot>
                    <tr className='border border-gray-300 bg-gray-300 sticky -bottom-1'>
                        {/*
                        <td> <HJButton>Save Draft</HJButton> </td>
                        <td> <HJButton>Delete Draft</HJButton> </td>
                        */}
                        <td> </td>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                        {achType === 'I' && <td> </td> /* Add an extra column if type is I, since the table is wider */}
                        <td className='font-bold'> Totals </td>
                        <td>{currencyFormatter.format(ccTotal())}</td>
                        <td>{currencyFormatter.format(cashCheckTotal())}</td>
                        <td>{currencyFormatter.format(formTotal())}</td>
                        <td> 
                            <SubmitButton />
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    } else {
        return <></>
    }
}
