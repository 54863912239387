import React from 'react'
import { currencyFormatter } from '../../utils/helpers';

interface CurrencyCellProps {
    value: number,
}

export const CurrencyCell: React.FC<CurrencyCellProps> = ({value}) => {
    return (
        <>
        {currencyFormatter.format(value)}
        </>
    );
}