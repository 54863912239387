import React, { useEffect, useRef, useState } from 'react'
import { DropdownItem } from './DropdownItem'
import { DropdownChangeEvent } from './DropdownChangeEvent';
import { DropdownDataProp } from './DropdownDataProp';


interface DropdownProps {
  data: (string|DropdownDataProp)[],
  defaultSelectText?: string,
  name: string,
  firstItemSelected?: boolean,
  onChange?: (changeEvent: DropdownChangeEvent) => void,
  value?: string,
  selectedIndex?: number,
  required?: boolean,
  className?: string,
  openAbove?: boolean,
}

export const Dropdown: React.FC<DropdownProps> = ({data, defaultSelectText="Select...", name, onChange, value, selectedIndex, required=false, className, openAbove=false}) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(value);
  const [index, setIndex] = useState<number>(selectedIndex || 0);
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleHideDropdown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowDropdown(false);
      }
    };
  
  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShowDropdown(false);
    }
  };
  
  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    window.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      window.removeEventListener("click", handleClickOutside, true);
    };
  },[]);

  
  useEffect(() => {
    if(onChange && selectedValue && showDropdown) onChange({currentTarget: {name, value: selectedValue, index}});
    setShowDropdown(false);
  },[selectedValue]);
  

  useEffect(() => {
    setSelectedValue(value);
  }, [value])

  const changeDropDownValue = (item: string | DropdownDataProp, index: number) => {
    setIndex(index);
    if(typeof item === "object" && item !== null){
      setSelectedValue(item.value);
    }
    else{
      setSelectedValue(item);
    }
  }

  return (
    <div className='inline-block'>
      <input type="text" name={name} id={name} defaultValue={selectedValue} required={required} className='opacity-0 w-0 h-0'/>
      <div ref={ref} className="dropdown inline-block">
          <button type='button' onClick={() => setShowDropdown(!showDropdown)} 
          className={`bg-gray-50 text-black font-semibold py-2 px-4 min-w-full inline-flex items-center ${className}`}>
            <span className="mr-1 flex-grow">{selectedValue || defaultSelectText}</span>
            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> </svg>
          </button>
          <ul hidden={!showDropdown} className={`dropdown-menu z-10 absolute text-gray-700 border-2 overflow-y-auto max-h-80 ${openAbove && 'bottom-full'}`}>
            {data.map((item, index) => 
              <DropdownItem item={item} index={index} updateDropdownValue={changeDropDownValue} key={index} />
            )}
          </ul>
      </div>
    </div>
  );
}