import React from 'react'
import { HJButton } from './Button/HJButton';
import Spinner from './Spinner';

interface WelcomeProps {
    setTab?: Function
}

export const Welcome: React.FC<WelcomeProps> = ({setTab}) => {

    const buttonSpacing = 'mx-2 my-4'

    if (setTab) {
        return (
            <div>
                <div className={`${buttonSpacing}`}>
                    <h1 className='text-2xl font-bold'>Welcome to the Payment Application Center</h1>
                    <p>Please choose from the buttons below</p>
                </div>
                <div className={`${buttonSpacing}`}>
                    <HJButton clickHandler={()=>setTab(1)}>Start a New Payment Application</HJButton>
                </div>
                { /*
                <div>
                    <HJButton clickHandler={()=>setTab(3)}>Edit Payment Application Draft</HJButton>
                </div>
                */ }
                <div className={`${buttonSpacing}`}>
                    <HJButton clickHandler={()=>setTab(2)}>Payment Application History</HJButton>
                </div>
            </div>
        );
    } else {
        return(
            <div><Spinner /> </div>
        )
    }
}