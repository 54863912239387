import React from 'react'
import { usePaymentApplicationContext } from '../context/PaymentApplicationContext';
import { useGetEntries } from '../hooks/useGetEntries';
import { HJButton } from './Button/HJButton';
import { PaymentApplicationHistoryTable } from './PaymentApplicationHistoryTable';

interface PaymentApplicationHistoryProps {
    repId?: number,
}

export const PaymentApplicationHistory: React.FC<PaymentApplicationHistoryProps> = ({repId}) => {

    const { repOffice } = usePaymentApplicationContext();
    const tableData = useGetEntries(repOffice?.salesRepNumber, true)
    const { refetch } = tableData;
    
    return (
        <div>
            <div>
                <div className='flex justify-center'>
                    <div className='flex-col'>
                        <p className='font-bold flex-row'>Please review and print your Payment Applications below</p>
                        <p className='font-bold flex-row'>{repId}</p>
                        <p className='flex-row'>(Only the most recent 1,000 will be shown)</p>
                    </div>
                    <div className='p-2'>
                        <HJButton clickHandler={ refetch }>Refresh Payment Application History</HJButton>
                    </div>
                </div>
                <div className='h-[60vh] md:h-[70vh] lg:h-[80vh] grow overflow-y-scroll mx-4'>
                    <PaymentApplicationHistoryTable tableData={tableData} />
                </div>
                
            </div>
        </div>
    );
}