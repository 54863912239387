import { AchEntry } from "../model/AchEntry";
import { apiRoutes } from "../routes";
import { useFetch } from "../utils/reactQuery";

export const useGetEntries = (salesRepId?: number, completed?: boolean) => {
  const context = useFetch<AchEntry[]>(
    `${apiRoutes.getEntries}/${salesRepId}/${completed}`,
    {},
    { 
      enabled: !!salesRepId,
      retry: false }
  );

  context.data?.forEach(x => { x.dateAddedDate = new Date(Date.parse(x.dateAdded)) });
  context.data?.forEach(x => { x.submitDateDate = new Date(Date.parse(x.submitDate)) });
  context.data?.forEach(x => { x.achItemCount = x.achItems.length });
  
  return context;
};