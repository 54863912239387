import React, { ChangeEventHandler, FocusEventHandler } from 'react'

type AutoCompleteType = 'off' | 'new-password' | 'on';

interface InputProps {
  label?: string,
  value?: any,
  type?: string,
  className?: string
  name: string,
  required?: boolean,
  checked?: boolean,
  maxLength?: number,
  autoComplete?: AutoCompleteType,
  onFocus?: FocusEventHandler<HTMLInputElement>,
  onBlur?: FocusEventHandler<HTMLInputElement>,
  onChange?: ChangeEventHandler<HTMLInputElement>,
  ref?: React.Ref<HTMLInputElement>
}

export const Input: React.FC<InputProps> = ({checked, label, value, type='text', className='', name, required=false, onChange, maxLength, onFocus, onBlur, autoComplete, ref}) => {
    return (
        <>
          {!!label && <label htmlFor={name}>{`${label}${required ? '*:' : ':'}`}</label>}
          <input type={type} 
            value={value} 
            name={name} 
            checked={checked} 
            id={name} 
            className={`mx-4 ${className}`}  
            required={required} 
            onFocus={onFocus}
            onBlur={onBlur}
            autoComplete={autoComplete}
            ref={ref}
            maxLength={maxLength}
            onChange={onChange}/>
        </>
    );
}