import React from 'react'
interface NotifyCreditCellProps {
    value: boolean,
}

export const NotifyCreditCell: React.FC<NotifyCreditCellProps> = ({value}) => {
    return (
        <>
        {value ? "Yes" : "No"}
        </>
    );
}