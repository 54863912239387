import jsPDF from "jspdf";

export const currencyFormatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

export function dateToString(input: Date): string {
    //return JSON.stringify(input);
    return `${input.toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"})} ${input.toLocaleTimeString('en-us', {hour: "numeric", minute: "2-digit", second: "2-digit"})}`
}

export function stripTime(input: Date): Date {
    let newDate = input;
    newDate.setDate(newDate.getDate() - newDate.getDay());
    return newDate;
}

export function printHistoryPDF(pdfString: string) {
    const doc = new jsPDF('l', 'px', 'letter')

    doc.html(pdfString, {
        callback: (doc) => {
            doc.save(`${new Date().toLocaleDateString('en-us')}.pdf`)
        },
        x: 5,
        y: 5,
        html2canvas: {
            scale: 0.7
        },
        autoPaging: 'text',
        width: 275, //target width in the PDF document
        windowWidth: 792 //window width in CSS pixels
    });
}