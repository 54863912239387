export const apiRoutes = {
    getBusinessUnits: 'ach/business',
    getProducts: 'ach/products',
    getEntries: 'ach/entries',
    getCustomers: 'rep/customers',
    getPaymentId: 'ach/payment',
    getRepOffices: 'rep/offices',
    postEntry: 'ach/entry',
    printPdf: 'print'
};
    
export const pageRoutes = {
    main: '/'
};
  