import { AchEntry } from "../model/AchEntry";
import { apiRoutes } from "../routes";
import { usePost } from "../utils/reactQuery";

export const usePostPayment = () => {

  const context = usePost<AchEntry,AchEntry>(
    apiRoutes.postEntry,
  );

  return { ...context};

};