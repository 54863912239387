import axios from 'axios';
import Cookies from 'js-cookie';

if(process.env.NODE_ENV === 'development'){
  axios.defaults.baseURL = 'http://localhost:8080/'
} else{
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
}

export const api = {
  get: <T>(url: string, params?: object) =>
    axios.get<T>(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get('token')
      },
      ...params,
    }),
  post: <T>(url: string, data: any) =>
    axios.post<T>(url, data, {
      headers: {
        Authorization: "Bearer " + Cookies.get('token')
      },
    }),
  put: <T>(url: string, data: any) =>
    axios.put<T>(url, data, {
      headers: {
        Authorization: "Bearer " + Cookies.get('token')
      },
    }),
  patch: <T>(url: string, data: any) =>
    axios.patch<T>(url, data, {
      headers: {
        Authorization: "Bearer " + Cookies.get('token')
      },
    }),
  delete: <T>(url: string, params?: object) =>
    axios.delete<T>(url, {
      headers: {
        Authorization: "Bearer " + Cookies.get('token')
      },
      ...params
    }),
};