import { AxiosError, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { UseQueryOptions, useQuery, useMutation, useQueryClient } from "react-query";
import { QueryFunctionContext } from "react-query/types/core/types";
import { api } from "./api";



type QueryKeyT = [string, object | undefined];

const fetcherPost = <T>({queryKey}: QueryFunctionContext<QueryKeyT>, callback = (data:T) => data): Promise<T> => {
  const [url, data] = queryKey;
  return api
    .post<T>(url, data)
    .then((res: AxiosResponse) => callback(res.data))
    .catch((err: AxiosError) => {throw err.response});
};

const fetcher = <T>({queryKey, pageParam}: QueryFunctionContext<QueryKeyT>, callback = (data:T) => data): Promise<T> => {
    const [url, params] = queryKey;
    return api
      .get<T>(url, { params: { ...params, pageParam } })
      .then((res: AxiosResponse) => callback(res.data))
      .catch((err: AxiosError) => {throw err.response});
};

export const useFetch = <T>(
    url: string | null,
    params?: object,
    config?: UseQueryOptions<T, AxiosError, T, QueryKeyT>,
    callback?: (data:T) => T
  ) => {
    const context = useQuery<T, AxiosError, T, QueryKeyT>(
      [url!, params],
      ({ queryKey }) => fetcher({queryKey, meta: undefined}, callback),
      {
        enabled: !!url,
        onError: (error: AxiosError) =>{
          if(error.status === 401){
            Cookies.remove('token');
            window.location.href = `${process.env.REACT_APP_CAS_URL}?service=hjCentral&returnURL=${process.env.REACT_APP_BASE_URL}`;
          }
        },
        ...config,
      }
    );
  
    return context;
};

  
const useGenericMutation = <T, S>(
    func: (data: T | S) => Promise<AxiosResponse<S>>,
    url: string,
    params?: object,
    updater?: ((oldData: T, newData: S) => T) | undefined
  ) => {
    const queryClient = useQueryClient();
  
    return useMutation<AxiosResponse, AxiosError, T | S>(func, {
      onMutate: async (data) => {
        await queryClient.cancelQueries([url!, params]);
  
        console.log(url);
        console.log(params);
        const previousData = queryClient.getQueryData([url!, params]);
        console.log(previousData);
        
  
        queryClient.setQueryData<T>([url!, params], (oldData) => {
          return updater ? updater(oldData!, data as S) : (data as T);
        });
  
        return previousData;
      },
      onError: (error: AxiosError, _, context) => {
        queryClient.setQueryData([url!, params], context);
        if(error.status === 401){
          alert("Session expired! You will be redirected to authenticate again.")
          Cookies.remove('token');
          window.location.href = `${process.env.REACT_APP_CAS_URL}?service=hjCentral&returnURL=${process.env.REACT_APP_BASE_URL}`;
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([url!, params]);
      },
    });
};

export const usePost = <T, S>(
    url: string,
    params?: object,
    updater?: (oldData: T, newData: S) => T
  ) => {
    return useGenericMutation<T, S>(
      (data) => api.post<S>(url, data),
      url,
      params,
      updater
    );
};

export const useFetchPost = <T>(
  url: string | null,
  data?: object,
  config?: UseQueryOptions<T, AxiosError, T, QueryKeyT>,
  callback?: (data:T) => T
) => {
  const context = useQuery<T, AxiosError, T, QueryKeyT>(
    [url!, data],
    ({ queryKey }) => fetcherPost({queryKey, meta: undefined}, callback),
    {
      enabled: !!url,
      ...config,
    }
  );

  return context;
};