import React from 'react'
import { Row } from 'react-table';
import { usePrintHistoryPdf } from '../../hooks/usePrintHistoryPdf';
import { AchEntry } from '../../model/AchEntry';
import PrintIcon from '../../images/printIcon.jpg';
import Spinner from '../Spinner';
import { printHistoryPDF } from '../../utils/helpers';
interface PaymentHistoryPrintCellProps {
    value: Row,
}

export const PaymentHistoryPrintCell: React.FC<PaymentHistoryPrintCellProps> = ({value}) => {
    const {mutate, isLoading} = usePrintHistoryPdf();
    const print = () => {
        console.log(value)
        console.log(value.original)
        let thing: AchEntry = value.original as AchEntry;
        console.log(thing);
        
        mutate(value, {
            onSuccess: (data) => {
                console.log(data.data);
                printHistoryPDF(data.data)
            },
        });
    }
    return (
        <>
            {isLoading ? 
                <Spinner />
            :
                <img className='inline w-10 mx-2' alt="Print Icon" src={PrintIcon} onClick={print}/>
            }
        </>
    );
}