import { business } from "../model/BusinessUnit";
import { apiRoutes } from "../routes";
import { useFetch } from "../utils/reactQuery";

export const useGetBusinessUnits = () => {
    const context = useFetch<business[]>(
      apiRoutes.getBusinessUnits, {},
      { retry: false }
    );
    
    return { ...context};
};