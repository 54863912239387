import React, { useState, useEffect } from 'react'
import { useTable, Column, useRowSelect, Row, useSortBy, Cell , useFilters, FilterTypes} from 'react-table'


interface TableProps<T extends object> {
    columns: Column<T>[];
    data: any[];
    selectHandler?: (row: Row<T>, e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => any;
    tableStyles?: string;
    reportSelected?: (selectedFlatRows:Row<T> | null)=>void;
    handleDoubleClick?: (row: Row<T>, e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
    getRowProps?: (row:Row<T>)=>{};
    getCellProps?: (cell:Cell<T>)=>{};
    updateMyDataNumber?: (rowIndex: number, columnId:string, value:number) => void;
    updateMyDataCheckbox?: (rowIndex: number, columnId:string, value:boolean) => void;
    updateMyDataText?: (rowIndex: number, columnId:string, value:string) => void;
    validateCheckboxChange?: (rowIndex: number, columnId:string, value:boolean) => boolean;
    disableFilters?:boolean;
    includeFooter?:boolean;
}

const selected = "bg-gray-500 text-white text-sm";
const unselected = "bg-white text-black text-sm";

// pass this in as getRowProps to remove select/unselected row styling from SortableSelectableTable
export const getRowPropsToExcludeSelect = function<T extends object>(row:Row<T>) {
  return {className: `${unselected} table-row divide-x-2 divide-gray-200 hover:bg-gray-100`,title:"", style:{}};
}

export const defaultGetRowProps = function<T extends object>(row:Row<T>) {
  return {className: `${row.isSelected ? selected : `${unselected} cursor-pointer`} table-row divide-x-2 divide-gray-200 ${row.isSelected ? "" :"hover:bg-gray-100 text-black"}`,title:"", style:{}}
}


interface DefaultColumnFilterProps<T extends object> {
  column:{
    filterValue:string;
    preFilteredRows: Row<T>[];
    setFilter: (filterValue:string | undefined) => void;
  }
}

function DefaultColumnFilter<T extends object>({
  column: { filterValue, preFilteredRows, setFilter },
}:DefaultColumnFilterProps<T>) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      onClick={(e) => e.stopPropagation()} // prevent click from propogating to parent (click to sort)
      placeholder={`Search ${count} records...`}
      title={`Search ${count} records...`}
      className="text-sm"
    />
  )
}

export const SortableSelectableTable = <T extends object>({columns, data, selectHandler, tableStyles, reportSelected, handleDoubleClick= (row,e)=>{}, getRowProps= defaultGetRowProps, getCellProps =(cell) => ({}), updateMyDataNumber = (rowIndex, columnId, value ) => {}, updateMyDataCheckbox = (rowIndex, columnId, value ) => {},updateMyDataText = (rowIndex, columnId, value ) => {}, validateCheckboxChange = (rowIndex, columnId, value ) => true,disableFilters=true, includeFooter=false}: TableProps<T>, ) => {

  const filterTypes = React.useMemo(
    () => ({

      text: (rows:Row<T>[], id:number, filterValue:string) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    } as any as FilterTypes<T>), // TO DO: how should this be done correctly in typescript without casting to any?
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  function onSelect(row: Row<T>, e: React.MouseEvent<HTMLTableRowElement, MouseEvent>): void{
    if(row !== currentRow){
      setCurrentRow(row)
      toggleAllRowsSelected(false);
      row.toggleRowSelected();
      if(selectHandler !== undefined) {
        selectHandler(row, e);
      }
    }
  } 
  let [currentRow, setCurrentRow] = useState<Row<T> | undefined>()
  const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  footerGroups,
  rows,
  prepareRow,
  toggleAllRowsSelected,
  selectedFlatRows
  } = useTable<T>({columns, data, updateMyDataNumber, updateMyDataCheckbox, updateMyDataText, validateCheckboxChange, defaultColumn, filterTypes,
  autoResetPage: false, 
  autoResetExpanded: false,
  autoResetGroupBy: false,
  autoResetSelectedRows: false,
  autoResetSortBy: false,
  autoResetFilters: false,
  autoResetRowState: false,
  disableFilters: disableFilters},
  useFilters, useSortBy, useRowSelect);



  useEffect(() => {
    if(reportSelected){
        if(selectedFlatRows.length){
          reportSelected(selectedFlatRows[0]);
        }
        else{
          reportSelected(null);
        }
    }
  },[selectedFlatRows]);

  return (
    <table {...getTableProps({className: tableStyles})}>
      <thead>
        { headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps({className: "bg-gray-200 text-md sticky top-0", style: {insetBlockStart: 0}})}>
            { headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())} className="p-1">
                { column.render('Header') }
                {/* Add a sort direction indicator */}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
                <div>
                  {column.canFilter ? column.render('Filter') : null}
                </div>
              </th>
            )) }
          </tr>
        )) }
      </thead>
      <tbody {...getTableBodyProps()}>
        {
        rows.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps(getRowProps(row))} onClick={e => onSelect(row, e)} onDoubleClick={(e) => handleDoubleClick(row,e)} >
              {
              row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps(getCellProps(cell))} >
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
      {includeFooter && 
        <tfoot>
          {footerGroups.map(group => (
            <tr {...group.getFooterGroupProps({className: "bg-gray-200 divide-x-2 divide-white text-md sticky", style: {insetBlockEnd: 0}})}>
              {group.headers.map(column => (
                <td {...column.getFooterProps()}>{column.render('Footer')}</td>
              ))}
            </tr>
          ))}
        </tfoot>
      }
    </table>
  )
}