import { AxiosError } from 'axios';
import React  from 'react'
import { UseQueryResult } from 'react-query';
import { Column } from 'react-table';
import { AchEntry } from '../model/AchEntry';
import Spinner from './Spinner';
import { CurrencyCell } from './Table/CurrencyCell';
import { DateCell } from './Table/DateCell';
import { NotifyCreditCell } from './Table/NotifyCreditCell';
import { PaymentHistoryPrintCell } from './Table/PaymentHistoryPrintCell';
import { getRowPropsToExcludeSelect, SortableSelectableTable } from './Table/SortableSelectableTable';


interface HistoryTableProps {
    tableData: UseQueryResult<AchEntry[], AxiosError<unknown, any>>
}

export const PaymentApplicationHistoryTable: React.FC<HistoryTableProps> = ({tableData}) => {

    const {data: state,isLoading:dataLoading, isRefetching} = tableData


    const historyHeaders: Column<AchEntry>[] = [
    {
        Header: 'Payment ID',
        accessor: row => row.hjAchPaymentId
    },
    {
        Header: 'Requested ACH Date',
        accessor: "dateAddedDate",
        Cell: DateCell
    },
    {
        Header: 'Date Submitted',
        accessor: "submitDateDate",
        Cell: DateCell
    },
    {
        Header: 'Payment Items',
        accessor: "achItemCount",
    },
    {
        Header: 'Payment Type',
        accessor: "hjAchProduct",
    },
    {
        Header: 'Notify Credit',
        accessor: "hjNotifyCrMgr",
        Cell: NotifyCreditCell
    },
    {
        Header: 'Total',
        accessor: "hjAchAmtRec",
        Cell : CurrencyCell
    },
    {
        Header: 'Print',
        accessor: row => row,
        Cell : PaymentHistoryPrintCell
    },
    ]

    if (state) {
        return (
            <>
                {isRefetching && <Spinner>Updating History</Spinner>}
                <SortableSelectableTable columns={historyHeaders} data={state} getRowProps={getRowPropsToExcludeSelect} tableStyles="w-full text-center"/>
            </>
        );
    } else if (dataLoading) {
        return (
            <Spinner>Loading History</Spinner>
        );
    } else {
        return <></>
    }

}
