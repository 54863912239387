import React, { ReactElement} from 'react'
import { RepOffice } from '../model/RepOffice'
import HJLogo  from '../images/HJ_logo.png'
import { HJButton } from './Button/HJButton'

export interface Tab {
    tabName: string,
    element: ReactElement,
    isDisabled?:boolean
}

interface TabbedViewProps {
    activeTab: Tab,
    tabsList: Tab[],
    setTabFromIndex: Function,
    className?: string
    repOffice?: RepOffice
}

export const TabbedView: React.FC<TabbedViewProps> = ({activeTab, tabsList, setTabFromIndex, className="flex flex-col w-screen h-full", repOffice}) => {
    
    const selected = 'bg-gray-50 text-black font-bold underline hover:text-hjcoral cursor-default';
    const unselected = 'bg-gray-300 text-black hover:text-hjcoral hover:underline cursor-pointer';
    const disabled = 'bg-gray-300 text-gray-600 cursor-default';

    const repName = repOffice?.officeName

    const logout = () => {
        window.location.href = `${process.env.REACT_APP_CAS_URL}/?event=logout&service=hjCentral&returnURL=${process.env.REACT_APP_BASE_URL}`
    }
    
    return (
        <div className={className}>
            <div className='flex justify-evenly text-xl nav sticky bg-gray-200 top-0'>
                <button onClick={() => { setTabFromIndex(0) } } className="mr-auto ml-2">
                    <img src={HJLogo} className='h-16 object-contain p-2' alt='Herff Jones Logo'/>
                </button>
                <ul className='flex'>
                    {tabsList.map((item, index) => {
                        return (
                            <li key={item.tabName} onClick={() => item.isDisabled? void 1 :setTabFromIndex(index)} className={`p-3 my-auto px-4,${item.isDisabled ? disabled : (activeTab.tabName === item.tabName ? selected : unselected)}` }>
                                {item.tabName}
                            </li>
                        );
                    })}
                </ul>
                <div className="ml-auto mr-2">
                    <p className='my-auto'>Welcome, {repName}</p>
                    <HJButton classes='my-3' clickHandler={logout}>Logout</HJButton>
                </div>
            </div>
            <div className='bg-white h-full text-black'>
                {activeTab.element}
            </div>
        </div>
    );
}