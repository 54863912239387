import { AchItem } from "../model/AchItem";
import { AchEntry } from "../model/AchEntry";

export const defaultPaymentEntry: AchEntry = {
    hjAchPaymentId: -1,
    salesPerson: -1,
    hjAchProduct: "RW",
    dateAdded: "01/02/2022",
    dateAddedDate: new Date(),
    hjAchAmtRec: 0,
    hjNotifyCrMgr: false,
    hjAchRepNotes: '',
    applicationSource: 'hjconnection',
    hjAchProductDesc: 'Rings Warwick',
    custId: '-1',
    depositType: '03',
    submitDate: 'string',
    submitDateDate: new Date(),
    completed: false,
    achItems: [],
    achItemCount: 0,
    hjAchType: 'D'
}

export const emptyPaymentEntry: AchEntry = { hjNotifyCrMgr: false, achItems: [] as AchItem[] } as AchEntry