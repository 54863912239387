import React from 'react'
import { DropdownDataProp } from './DropdownDataProp';

interface DropdownItemProps {
    updateDropdownValue: (item: string | DropdownDataProp, index: number) => void,
    item: string | DropdownDataProp,
    index: number
}

export const DropdownItem: React.FC<DropdownItemProps> = ({updateDropdownValue, item, index}) => {
    return (
        <li>
            <button type="button" className={`bg-white 
                hover:bg-gray-200
                py-2 
                px-4 
                block 
                w-full
                whitespace-no-wrap
                    ${typeof item === "object" && item !== null && item.class? item.class : ''}`}
                onClick={() => updateDropdownValue(item, index)}>{typeof item === "object" && item !== null ? item.value : item}</button>
        </li>
    );
} 
