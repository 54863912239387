import { AchProduct } from "../model/AchProduct";
import { apiRoutes } from "../routes";
import { useFetch } from "../utils/reactQuery";

export const useGetProducts = () => {
  const context = useFetch<AchProduct[]>(
    apiRoutes.getProducts, {},
    { retry: false }
  );
  
  return { ...context};
};