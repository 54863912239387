import React from 'react'
interface DateCellProps {
    value: Date | undefined,
}

export const DateCell: React.FC<DateCellProps> = ({value}) => {
    if (value)
    return (
        <>
        {value.getUTCMonth()+1}/{value.getUTCDate()}/{value.getUTCFullYear()}
        </>
    );
    else return <></>;
}