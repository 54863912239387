import { createContext, ReactNode, useContext, useReducer } from "react";
import { emptyPaymentEntry } from "../fixtures/paymentEntries";
import { AchEntry } from "../model/AchEntry";
import { RepOffice } from "../model/RepOffice";

export type State = {
    formEntry: AchEntry,
    repOffice: RepOffice,
}
export type Action = {type: 'setRepOffice', data: RepOffice} |
                     {type: 'setFormEntry', data: AchEntry}
export type Dispatch = (action: Action) => void;
interface ContextType {
  repOffice?: RepOffice,
  formEntry: AchEntry,
  dispatch: Dispatch,
}
const PaymentApplicationContext = createContext<ContextType | undefined>(undefined);
const contextReducer = (state: State, action: Action) => {
    switch(action.type) {
        case "setRepOffice":
            return {...state, repOffice: action.data}
        case "setFormEntry":
            return {...state, formEntry: action.data}
    }

}

const defaultState = {
    formEntry: emptyPaymentEntry,
    repOffice: {} as RepOffice
}

export const PaymentApplicationContextProvider = ({children}: {children: ReactNode}) => {
    const [state, dispatch] = useReducer(contextReducer, defaultState);
    return (
        <PaymentApplicationContext.Provider value={{formEntry: state.formEntry, repOffice: state.repOffice, dispatch: dispatch}}>
            {children}
        </PaymentApplicationContext.Provider>
    )
}

export const usePaymentApplicationContext = () => {
    let context = useContext(PaymentApplicationContext);

    if(!context) throw new Error('usePaymentApplicationContext hook cannot be used outside context provider!')

    return context;
}