import { useEffect, useState } from 'react';
import './App.css';
import { Tab, TabbedView } from './Components/TabbedView';
import { Welcome } from './Components/Welcome';
import { PaymentApplicationForm } from './Components/PaymentApplicationForm';
import { PaymentApplicationHistory } from './Components/PaymentApplicationHistory';
import { ReactQueryDevtools } from 'react-query/devtools';
import { PaymentApplicationContextProvider } from './context/PaymentApplicationContext';
import Cookies from 'js-cookie';
import { useGetUsername } from './hooks/useGetUsername';
import { useGetRepOffices } from './hooks/useGetRepOffices';
import { OfficeSelector } from './Components/OfficeSelector';
import { RepOffice } from './model/RepOffice';
import Spinner from './Components/Spinner';


function App() {

  const [username, setusername] = useState<string>();
  const {data} = useGetUsername(username);
  const {data: offices} = useGetRepOffices();
  const [repOffice, setRepOffice] = useState<RepOffice>();

  useEffect(() => {
      console.log(window.location.hostname);
      console.log(window.location.href);
    const searchParams = new URLSearchParams(document.location.search)
    let ticket = searchParams.get('ticket');
    if(!Cookies.get('token')){
      if(!ticket) {
        window.location.href = `${process.env.REACT_APP_CAS_URL}/?service=hjCentral&returnURL=${process.env.REACT_APP_BASE_URL}`;
      } else {
        setusername(ticket);
      }
    }
  }, [])

  useEffect(() => {
    if(data){
      Cookies.set('token',data);
      if(process.env.REACT_APP_BASE_URL) window.location.href = process.env.REACT_APP_BASE_URL;
    }
  },[data]);
  
  const tabOptions: Tab[] = [
    {tabName: 'Welcome', element: <Welcome setTab={setTabFromIndex}/> },
    {tabName: "Payment Application Form", element: <PaymentApplicationForm />}, 
    {tabName: "Payment Application History", element: <PaymentApplicationHistory />}, 
    //{tabName: "Payment Application Drafts", element: <PaymentApplicationDrafts setActiveTab={setTabFromIndex}/>}
  ]
  const [tabsList, _setTabsList] = useState<Tab[]>(tabOptions);
  const [activeTab, setActiveTab] = useState<Tab>(tabsList.find(tab => !tab.isDisabled) || tabsList[0]);

  function setTabFromIndex(index: number) {
    const desiredTab = tabsList[index]
    if (desiredTab) setActiveTab(desiredTab);
  }

  return (
    <div className="App">
      <PaymentApplicationContextProvider>
        {!repOffice ? 
          offices ? 
            <OfficeSelector officeData={offices} setRepOffice={setRepOffice}/>
          :
            <div className='h-screen flex justify-center items-center' ><Spinner>Loading...</Spinner></div>
        :
          <TabbedView activeTab={activeTab} tabsList={tabsList} setTabFromIndex={setTabFromIndex} repOffice={repOffice}/>
        }
      </PaymentApplicationContextProvider>
      <ReactQueryDevtools initialIsOpen={false}/>   
    </div>
  );
}

export default App;
