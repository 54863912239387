import { useFetchPost } from "../utils/reactQuery";

export const useGetUsername = (ticket?: string) => {
  const context = useFetchPost<string>(`ticket`,{ticket},
    { 
        enabled: !!ticket,
        retry: false,
        onError: (err) => {
            if(err.status === 401) {
              window.location.href = `${process.env.REACT_APP_CAS_URL}?event=logout&service=hjCentral&returnURL=${process.env.REACT_APP_BASE_URL}`;
            } else if (err.status === 500) {
              alert("An unexpected error occurred while validating your login! Try clearing cache and/or cookies, or check back later.")
            }
        }
    }
  );
  return { ...context};
}