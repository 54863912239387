import { AchItem, TypeDItem, TypeIItem } from "../model/AchItem"
import { AchEntry } from "../model/AchEntry";

export const defaultType1Item: TypeIItem = {
    seqNum: 0,
    hjAchPaymentId: 1,
    salesPerson: 1,
    custId: '-1',
    custName: '-1',
    businessUnit: 1002,
    item: "0",
    hjCreditCardAmt: 5,
    hjCashCheckAmt: 2,
    hjAchAmtDet: 7,
    hjAchRepNotes: ""
}

export const emptyTypeIItem: TypeIItem = { hjCashCheckAmt: 0, hjCreditCardAmt: 0, hjAchAmtDet: 0 } as TypeIItem;
export const emptyTypeDItem: TypeDItem = { hjCashCheckAmt: 0, hjCreditCardAmt: 0, hjAchAmtDet: 0 } as TypeDItem;

export const getEmptyItemFromEntry = (entry: AchEntry) => {
    switch(entry.hjAchType) {
        case 'I':
            return emptyTypeIItem;
        case 'D':
            return emptyTypeDItem;
        default:    
    }
    return undefined
}

export const emptyItem = (type: AchItem) => {
    if (type) {
        if ('businessUnit' in type) return emptyTypeIItem;
        else return emptyTypeDItem;
    }
}

export const defaultTypeDItem: TypeDItem = {
    seqNum: 0,
    hjAchPaymentId: 1,
    salesPerson: 1,
    custId: '-1',
    custName: '-1',
    hjDepositPo: '',
    hjCreditCardAmt: 6,
    hjCashCheckAmt: 5,
    hjAchAmtDet: 11,
    hjAchRepNotes: ""
}