import React, { useCallback, useEffect } from 'react'
import { Dropdown } from './Dropdown/Dropdown';
import { Input } from './Input';
import { AchForm } from './AchForm';
import { dateToString, printHistoryPDF } from '../utils/helpers';
import { usePaymentApplicationContext } from '../context/PaymentApplicationContext';
import { useGetProducts } from '../hooks/useGetProducts';
import { emptyItem, getEmptyItemFromEntry } from '../fixtures/achItems';
import { useGetPaymentId } from '../hooks/useGetPaymentId';
import { useGetCustomers } from '../hooks/useGetCustomers';
import { usePostPayment } from '../hooks/usePostPayment';
import { AchEntry } from '../model/AchEntry';
import { HJButton } from './Button/HJButton';
import Spinner from './Spinner';
import { useGetEntries } from '../hooks/useGetEntries';
import { AchItem } from '../model/AchItem';
import { usePrintHistoryPdf } from '../hooks/usePrintHistoryPdf';

export const PaymentApplicationForm: React.FC = () => {

    const {formEntry: state, dispatch: setState, repOffice: {salesRepNumber: repId} = {}} = usePaymentApplicationContext();
    
    const {data: customerOptions, isFetching: fetchingCustomers,  refetch: refetchCustomers, isRefetching} = useGetCustomers(repId);
    const {data: productTypes} = useGetProducts()
    const {data: paymentId, refetch: refetchPaymentId} = useGetPaymentId()
    const {refetch: refetchEntries} = useGetEntries(repId, true);
    const {mutate: postFunction} = usePostPayment();
    const {mutate: printPDF} = usePrintHistoryPdf();

    const notifyCredit = () => {
        if (state.hjAchType === 'N') return true;
        else return state.hjNotifyCrMgr
    }

    const flipNotifyCredit = () => {
        if (state.hjAchType === 'N') setState({type: 'setFormEntry', data: {...state, hjNotifyCrMgr: true}});
        else return setState({type: 'setFormEntry', data: {...state, hjNotifyCrMgr: !state.hjNotifyCrMgr}});
    }
    const setFormNotes = (notes: string) => {
        setState({type:'setFormEntry', data: {...state, hjAchRepNotes: notes}});
    }

    const length = 5; // Number of items on load

    function createRepCustID(salesRepNumber: number) {
        let repString:String = String(salesRepNumber);
        if (repString.length === 1)
        {
            return "0000000" + repString + "000";
        }
        else if (repString.length === 2)
        {
            return "000000" + repString + "000";
        }
        else if (repString.length === 3)
        {
            return "00000" + repString + "000";
        }
        return "0000" + repString + "000";
    }

    
    function updateProduct(newVal: number) {
        if (productTypes && productTypes[newVal]) {
            let newState = {...state}
            newState.hjAchProduct = productTypes![newVal].fieldValue
            newState.hjAchProductDesc = productTypes![newVal].description
            newState.hjAchType = productTypes![newVal].hjAchType
            newState.achItems = fillItems(newState)
            setState({type: 'setFormEntry', data: {...newState}})
        }
    }

    const fillItems = (entry: AchEntry) => {
        let item = getEmptyItemFromEntry(entry);
        const newArray = new Array(length)
        for (let i = 0; i < newArray.length; i += 1) {
            newArray[i] = item
        }
        return newArray;
    }


    const productStrings = () => {
        if (productTypes !== undefined) return productTypes.map(x => x.description) 
        else return []
    }


    const enteredItems = useCallback(() => {
        return state.achItems.filter(item => JSON.stringify(item) !== JSON.stringify(emptyItem(item)))
    }, [state.achItems]);

    useEffect(() => {
        if (state.hjAchType === 'N') return; // Spreadsheet
        if (state.achItems.length - enteredItems().length < 2) { // Add another item
            let addingItems = {...state}.achItems
            let newItem = getEmptyItemFromEntry(state);
            if (!newItem) return
            addingItems.push(newItem);
            let newState = {...state, achItems: addingItems}
            setState({type: 'setFormEntry', data: {...newState}})
        }
    }, [enteredItems, setState, state]);

    const submitForm = () => {
        if(paymentId && repId) {
            let eItems: AchItem[] = state.hjAchType !== 'N' ? enteredItems() : []
            eItems?.forEach(x => { 
                x.hjAchAmtDet = x.hjCashCheckAmt + x.hjCreditCardAmt;
                x.salesPerson = repId;
                x.hjAchPaymentId = paymentId;
            });

            let adding: AchEntry = {
                ...state,
                hjAchPaymentId: paymentId,
                achItems: eItems ?? [],
                achItemCount: undefined,
                salesPerson: repId!,
                submitDate: dateToString(new Date()),
                dateAdded: dateToString(new Date()),
                dateAddedDate: undefined,
                submitDateDate: undefined,
                completed: true,
                custId: createRepCustID(repId!),
                depositType: '03',
            }

            // We want to ensure notify is always true on N type, so we explicitly set it here as a backup
            if (adding.hjAchType === 'N') adding.hjNotifyCrMgr = true;

            // This value is set on state directly for Type N Form, so we set it on the other types manually
            if (adding.hjAchType !== 'N') adding.hjAchAmtRec = eItems?.map(item => item.hjAchAmtDet).reduce((prev, next) => prev + next, 0);

            postFunction(adding, {
                onSuccess: () => {
                    refetchPaymentId();
                    refetchEntries();
                    let shouldPrint = window.confirm("Your ACH has been submitted successfully! Would you like to print?");
                    if(shouldPrint) {
                        printPDF(adding, {
                            onSuccess: (data) => {
                                printHistoryPDF(data.data);
                            }
                        });
                    }
                },
                onError: (err) => {
                    let errRes = err.response;
                    if(errRes){
                        alert(`An error has occurred while saving! Please try again later. Error Code: ${errRes.status}`);
                    } else {
                        alert(`An error has occurred while saving! Please try again later.`);
                    }
                }
            });
        }
    }

    const refreshCustomers = () => {
        let newState = {...state }; // Clear out form
        newState.achItems = fillItems(newState);
        setState({type: 'setFormEntry', data: {...newState}})
        refetchCustomers() // Fetch again
    }

    return (
        <div className='flex justify-center'>
            <div className=''>
                <div className='py-2'>
                    <div className='flex justify-start my-auto'>
                        <HJButton clickHandler={refreshCustomers}>Refresh Customer List from Server</HJButton>
                        <p className='mx-3 my-auto'>Payment ID: {paymentId}</p>
                        { /*
                        <Input type='date' name='achReqDate' label='Requested ACH Date' value={requestDateString} onChange={(e) => updateRequestDate(e.currentTarget.name)} />
                        */ }
                    </div>
                    <div className='flex justify-start my-2'>
                        <div className='my-auto pr-2'>
                            <label htmlFor='payType' className='my-auto'>Payment Type:</label>
                            <Dropdown data={productStrings()} value={state.hjAchProductDesc} name='payType' className='w-64' onChange={(e) => updateProduct(e.currentTarget.index)} />
                        </div>
                        <div className='flex align-middle'>
                            <label htmlFor='notifyCredit' className='my-auto'>Notify Credit:</label>
                            <Input type='checkbox' name='notifyCredit' checked={notifyCredit()} onChange={() => flipNotifyCredit()}/>
                        </div>
                        <div className='flex align-middle'>
                            <label htmlFor='hjAchRepNotes' className='my-auto'>Notes:</label>
                            <Input type="text" name="hjAchRepNotes" value={state.hjAchRepNotes} maxLength={30} onChange={(e) => setFormNotes(e.target.value)} className={'bg-gray-200 border border-gray-200 py-3 px-4 focus:outline-none focus:bg-white focus:border-gray-500'}/>
                        </div>
                    </div>
                </div>
                <div className='inline-block justify-center static'>
                    {isRefetching && <Spinner>Reloading Schools...</Spinner>}
                    {fetchingCustomers && <Spinner>Loading Schools...</Spinner>}
                    {state.hjAchType && <AchForm customerOptions={customerOptions} achType={state.hjAchType} submitForm={submitForm}/>}
                </div>
                
            </div>
        </div>
    );
}