import React from 'react'

type ButtonType = "button" | "submit";

interface ButtonProps {
    children: string,
    classes?: string,
    clickHandler?: (e:any) => void,
    isDisabled?: boolean,
    type?: ButtonType,
}

export const HJButton: React.FC<ButtonProps> = ({children, classes, clickHandler, isDisabled=false, type="button"}) => {
        return (
            <button 
                type={type} 
                onClick={clickHandler} 
                className={`${classes} px-4 py-2 border border-black text-black font-bold hover:text-hjcoral hover:border-hjcoral disabled:bg-gray-50 disabled:border-gray-400 disabled:text-gray-400 disabled:font-normal`}
                disabled={isDisabled}
            >
                {children.toUpperCase()}
            </button>
        );
}