import { apiRoutes } from "../routes";
import { useFetch } from "../utils/reactQuery";

export const useGetPaymentId = () => {
    const context = useFetch<number>(
      apiRoutes.getPaymentId, {},
      { retry: false }
    );
    
    return { ...context};
};