import { TypeIItem } from "../model/AchItem";
import XMark from  '../images/xmark.svg';
import { currencyFormatter } from "../utils/helpers";
import { Dropdown } from "./Dropdown/Dropdown";
import { UpdateAction } from "./AchForm";

interface TypeITableLineProps {
    rowIndex: number,
    className: string,
    achItem: TypeIItem,
    customerNames: string[],
    customerNumbers: string[],
    businessUnits: string[],
    selectedBusiness: string,
    updateReducer: (action: UpdateAction) => void,

}

export const TypeITableLine: React.FC<TypeITableLineProps> = ({rowIndex,className,achItem,customerNames,businessUnits, selectedBusiness,
                                                                customerNumbers, updateReducer }) => {

    const rowTotal = () => {
        let ccAmt = achItem.hjCreditCardAmt ? achItem.hjCreditCardAmt : 0
        let cashCheckAmt = achItem.hjCashCheckAmt ? achItem.hjCashCheckAmt : 0
        return currencyFormatter.format(ccAmt + cashCheckAmt);
    }

    return (
        <tr className={`${className} my-1`}>
            <td className=''>{rowIndex+1}</td>
            <td>
                <button type="button" onClick={() => updateReducer({type: 'clear', data: {row: rowIndex}})}>
                    <img className='w-5 mx-4 inline cursor-pointer' src={XMark} alt="Click to Clear Line"/>
                </button>
            </td>
            <td className='mx-2 py-1'>
                <Dropdown value={achItem.custName} data={customerNames} name='custName' 
                        onChange={(e) => updateReducer({type: 'customerName', data: {row: rowIndex, changeEvent: e}})} />
            </td>
            <td className='mx-2 py-1'>
                <Dropdown value={achItem.custId} data={customerNumbers} name='custId' 
                        onChange={(e) => updateReducer({type: 'customerNumber', data: {row: rowIndex, changeEvent: e}})} />
            </td>
            <td className='mx-2 py-1'>
                <Dropdown value={selectedBusiness} data={businessUnits} name='businessUnit' 
                        onChange={(e) =>updateReducer({type: 'businessUnit', data: {row: rowIndex, changeEvent: e}})} />
            </td>
            <td className='mx-2 py-1'>
                <input className='w-28' name='item' value={achItem.item ? achItem.item: ''} 
                    onChange={(e) => updateReducer({type: 'state', data: {row: rowIndex, changeEvent: e}})} />
            </td>
            <td className='mx-2 py-1'>
                <input type='number' onFocus={e => e.target.select()} className='w-20' name='creditCardTotal' value={achItem.hjCreditCardAmt || ''} 
                    onChange={(e) => updateReducer({type: 'creditCard', data: {row: rowIndex, value: e.target.valueAsNumber}})} />
            </td>
            <td className='mx-2 py-1'>
                <input type='number' onFocus={e => e.target.select()} className='w-20' name='cashCheckTotal' value={achItem.hjCashCheckAmt || ''} 
                    onChange={(e) => updateReducer({type: 'cashCheck', data: {row: rowIndex, value: e.target.valueAsNumber}})} />
            </td>
            <td className='mx-2 py-1'>
                {rowTotal()}
            </td>
            <td className="mx-2 py-1">
                <input name='hjAchRepNotes' value={achItem.hjAchRepNotes ? achItem.hjAchRepNotes : ''} maxLength={30}
                    onChange={(e) => updateReducer({type: 'state', data: {row: rowIndex, changeEvent: e}})} />
            </td>
        </tr>
    );
}; 